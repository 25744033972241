import {
  CommonState,
  SectorData,
  ViewJobState
} from "@/store/modules/common/interfaces";
import {
  COMMON_LOADING,
  GET_JOB_BY_ID,
  GET_ROLE_BY_NAME,
  GET_UNIQUE_SECTORS,
  ROLES,
  VIEW_JOB_STATE
} from "@/store/modules/common/constants";
import { Roles } from "@/interfaces/data_objects/roles";
import { clean_string } from "@/utils/global";
import { Jobs } from "@/interfaces/data_objects/jobs";

export default {
  [COMMON_LOADING]: (state: CommonState): boolean => state.loading,
  [GET_UNIQUE_SECTORS]: (state: CommonState): SectorData[] => state.sectors,
  [ROLES]: (state: CommonState): Roles[] => state.roles,
  [GET_JOB_BY_ID]: (state: CommonState): Jobs | null => state.searched_job,
  [GET_ROLE_BY_NAME]:
    (state: CommonState) =>
    (role_name: string): Roles | undefined => {
      const role = clean_string(role_name);
      return state.roles.find(
        (_role: Roles) => clean_string(_role.role_name) === role
      );
    },
  [VIEW_JOB_STATE]: (state: CommonState): ViewJobState => state.view_job_state
};
