import { edu_portal_institute_base_url } from "@/store/urls/base_urls";

export const InstituteApiUrls = {
  UPLOAD_PROGRAM: edu_portal_institute_base_url + "upload-program/",
  FETCH_INSTITUTES: edu_portal_institute_base_url + "institutes/",
  //programs/:institute_id"
  FETCH_INSTITUTE_PROGRAM: edu_portal_institute_base_url + "programs/",
  DEGREE_JOB_COMPARISON: edu_portal_institute_base_url + "job/compare/",
  DEGREE_TOP_JOBS: edu_portal_institute_base_url + "degree-top-jobs/",
  INSTITUTES_COMPARE: edu_portal_institute_base_url + "institutes/compare/",
  UPSKILL_COURSES: edu_portal_institute_base_url + "upskill-courses/"
} as const;
