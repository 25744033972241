export const RESET_INSTITUTE_STATE = "RESET_INSTITUTE_STATE";
export const INSTITUTE_STATE_LOADING = "INSTITUTE_STATE_LOADING";

// For degree vs job market comparison
export const DEGREE_JOB_COMPARISON = "DEGREE_JOB_COMPARISON";

// For national degree vs global degree comparison
export const NATIONAL_GLOBAL_COMPARISON = "NATIONAL_GLOBAL_COMPARISON";
// For holding national degree extracted skills
export const NATIONAL_DEGREE_SKILLS = "NATIONAL_DEGREE_SKILLS";

// For holding global degree extracted skills
export const GLOBAL_DEGREE_SKILLS = "GLOBAL_DEGREE_SKILLS";

// For getting skill gap
export const DEGREE_GAP_LOADING = "DEGREE_GAP_LOADING";

// For holding national degree top job data
export const NATIONAL_DEGREE_TOPJOBS_WITH_SECTORS =
  "NATIONAL_DEGREE_TOPJOBS_WITH_SECTORS";

// For (getter) => fetching only industries from national degree topjobs
export const NATIONAL_DEGREE_TOPJOB_SECTORS = "NATIONAL_DEGREE_TOP_SECTORS";

// For (getter) => fetching selected industry jobs from national degree topjobs
export const NATIONAL_DEGREE_SELECTED_SECTOR_JOBS =
  "NATIONAL_DEGREE_SELECTED_SECTOR_JOBS";

// For (getter) => fetching selected job match/gap
export const NATIONAL_DEGREE_SELECTED_SECTOR_JOB_MATCH_GAP =
  "NATIONAL_DEGREE_SELECTED_SECTOR_JOB_MATCH_GAP";

// For fetching institutes
export const FETCH_INSTITUTES = "FETCH_INSTITUTES";
export const ALL_INSTITUTES = "ALL_INSTITUTES";
export const LOCAL_INSTITUTES = "LOCAL_INSTITUTES";
export const GLOBAL_INSTITUTES = "GLOBAL_INSTITUTES";
export const FETCH_INSTITUTE_PROGRAM = "FETCH_INSTITUTE_PROGRAM";

export const INIT_INSTITUTE_STATE = "INIT_INSTITUTE_STATE";

export const DEGREE_JOB_FILE_COMPARISON = "DEGREE_JOB_FILE_COMPARISON";

export const UPLOAD_NATIONAL_DEGREE = "UPLOAD_NATIONAL_DEGREE";

export const UPLOAD_GLOBAL_DEGREE = "UPLOAD_GLOBAL_DEGREE";

// FOr global degree skills extraction loading
export const GLOBAL_DEGREE_LOADING = "GLOBAL_DEGREE_LOADING";

// FOr national degree skills extraction loading
export const NATIONAL_DEGREE_LOADING = "NATIONAL_DEGREE_LOADING";

// For selected national institute
export const SELECTED_NATIONAL_INSTITUTE = "SELECTED_NATIONAL_INSTITUTE";
// For selected global institute
export const SELECTED_GLOBAL_INSTITUTE = "SELECTED_GLOBAL_INSTITUTE";

// For matched/skills gap & redundant skills
export const COMPARISON_OTHER_SKILLS_LOADING =
  "COMPARISON_OTHER_SKILLS_LOADING";

export const COMPARISON_OTHER_SKILLS = "COMPARISON_OTHER_SKILLS";

// For upskill career
export const UPSKILL_COURSES_LOADING = "UPSKILL_COURSES_LOADING";
export const UPSKILL_COURSES = "UPSKILL_COURSES";

export const UPSKILL_COURSE_PAYLOAD = "UPSKILL_COURSE_PAYLOAD";

// For returning selected job score
export const SELECTED_JOB_SCORE = "SELECTED_JOB_SCORE";
