import { RouteConfig } from "vue-router";
import { UserState } from "@/store/modules/auth/interfaces";
import { syllabi_comparison } from "@/router/sub_routes/institute/syllabi_comparison";
import { institute_students } from "@/router/sub_routes/institute/students";
import { institute_faculty } from "@/router/sub_routes/institute/faculty";

export const institute_routes: RouteConfig = {
  path: "/institute",
  component: () => import("@/views/modules/institute/Wrapper.vue"),
  meta: {
    user_state: [UserState.INSTITUTE]
  },
  children: [
    {
      path: "",
      redirect: {
        name: "institute-dashboard"
      }
    },
    {
      path: "dashboard",
      name: "institute-dashboard",
      component: () => import("@/views/modules/institute/Dashboard.vue"),
      meta: {
        user_state: [UserState.INSTITUTE]
      }
    },
    {
      path: "analysis",
      name: "analysis-dashboard",
      component: () => import("@/views/modules/institute/Analysis.vue"),
      meta: {
        user_state: [UserState.INSTITUTE]
      }
    },
    syllabi_comparison,
    institute_students,
    institute_faculty,
    {
      path: "error",
      name: "institute-error",
      component: () => import("@/views/ErrorPage.vue"),
      meta: {
        user_state: [UserState.INSTITUTE]
      }
    }
  ]
};
