











import Vue from "vue";
import { mapGetters, mapMutations } from "vuex";
import { ROOT_ERROR } from "@/store/modules/root/constants";

export default Vue.extend({
  name: "ErrorSnackbar",
  data() {
    return {
      error_snackbar: true
    };
  },
  created() {
    setTimeout(this.reset_error, 6000);
  },
  computed: {
    ...mapGetters({
      root_error: ROOT_ERROR
    })
  },
  methods: {
    ...mapMutations({
      set_root_error: ROOT_ERROR
    }),
    reset_error() {
      this.error_snackbar = false;
      this.set_root_error(null);
    }
  }
});
