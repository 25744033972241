import getters from "@/store/modules/institute/getters";
import mutations from "@/store/modules/institute/mutations";
import actions from "@/store/modules/institute/actions";
import { InstituteState } from "@/store/modules/institute/interfaces";
export default {
  namespaced: true,
  state: (): InstituteState => ({
    institute_loading: false,
    degree_gap_loading: false,
    national_degree_skills: null,
    global_degree_skills: null,
    national_degree_topjobs: null,
    institutes: [],
    global_degree_loading: false,
    selected_national_institute_data: {
      national_institute: null,
      selected_national_degree_content: "",
      selected_national_degree_file: null,
      national_institute_program: null
    },
    selected_global_institute_data: {
      global_institute: null,
      selected_global_degree_content: "",
      selected_global_degree_file: null,
      global_institute_program: null
    },
    national_degree_loading: false,
    comparison_other_skills_loading: false,
    comparison_other_skills: null,
    upskill_courses: null,
    upskill_courses_loading: false,
    upskill_course_payload: null
  }),
  getters: getters,
  mutations: mutations,
  actions: actions
};
