import axios, { AxiosResponse } from "axios";
import {
  FETCH_SKILLS,
  FETCH_SKILLS_GROUP,
  GET_JOB_BY_ID,
  GET_ROLES_LIST,
  GET_UNIQUE_SECTORS
} from "@/store/urls/common";
import {
  FetchSkillsGroupActionPayload,
  SkillSearchPayload
} from "@/store/modules/common/interfaces";

export const get_unique_sectors = async (): Promise<AxiosResponse> => {
  return await axios.get(GET_UNIQUE_SECTORS).then((response) => response.data);
};

export const get_roles_list = async (): Promise<AxiosResponse> => {
  return await axios.get(GET_ROLES_LIST);
};
export const get_job_by_id = async (job_id: number): Promise<AxiosResponse> => {
  return await axios.get(`${GET_JOB_BY_ID}${job_id}`);
};

export const fetch_skills_group = async (
  payload: FetchSkillsGroupActionPayload
): Promise<AxiosResponse> => {
  return await axios.post(FETCH_SKILLS_GROUP, payload);
};

export const fetch_skills = async (
  payload: SkillSearchPayload
): Promise<AxiosResponse> => {
  return await axios.post(FETCH_SKILLS, payload);
};
