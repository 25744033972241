import Vue from "vue";
import VueI18n, { LocaleMessages } from "vue-i18n";

Vue.use(VueI18n);

/**
 * load locales messages from the json files
 */
function loadLocaleMessages(): LocaleMessages {
  const locales = require.context(
    "./locales",
    true,
    /[A-Za-z0-9-_,\s]+\.json$/i
  );
  const messages: LocaleMessages = {};
  locales.keys().forEach((key) => {
    const matched = key.match(/([A-Za-z0-9-_]+)\./i);
    if (matched && matched.length > 1) {
      const locale = matched[1];
      messages[locale] = locales(key);
    }
  });
  return messages;
}
/**
 * Return the locale string of user selected language from the local storage of the browser
 */
function getLocal(): string | null {
  let locale;
  if (!localStorage.getItem("locale")) {
    localStorage.setItem("locale", "en");
    locale = localStorage.getItem("locale");
  } else {
    locale = localStorage.getItem("locale");
  }
  return locale;
}

export default new VueI18n({
  locale: getLocal() || "en",
  fallbackLocale: "en",
  messages: loadLocaleMessages()
});
