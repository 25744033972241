import ErrorService from "@/services/ErrorService";
import axios, { AxiosRequestConfig } from "axios";
import store from "@/store";
import { LOGOUT_USER } from "@/store/modules/auth/constants";
import i18n from "@/i18n";
import router from "@/router";
import { refresh_token } from "@/store/apis/auth";
import { clean_string } from "./global";
import { IApp } from "@/interfaces";
// Portal Core HCS
const portalIsCoreHCS =
  clean_string(process.env.VUE_APP_PORTAL) ===
  clean_string(IApp.Portals.COREHCS);

// Portal Core 43 HCMS
const portalIsCoreHCMS =
  clean_string(process.env.VUE_APP_PORTAL) ===
  clean_string(IApp.Portals.COREHCMS);
/**
 * axios interceptor for binding the access token with api call request
 * */
export const requestInterceptor = function (): any {
  axios.interceptors.request.use(
    async function (config: AxiosRequestConfig) {
      // Append necessary information in headers
      if (config.headers) {
        const token = localStorage.getItem("token");
        if (token) config.headers.token = token;
        config.headers.domain =
          portalIsCoreHCS || portalIsCoreHCMS
            ? "https://edu.hcms.ai"
            : window.location.href;
      }
      return config;
    },
    function (err) {
      return Promise.reject(err);
    }
  );
};

/**
 * axios interceptor for noticing error relevant to the api calling
 * */
export const errorInterceptor = function (): any {
  axios.interceptors.response.use(
    function (response) {
      return response;
    },
    async function (error): Promise<any> {
      // Response undefined means => backend didn't receive request from frontend
      // Means that firewall blocks backend app ip or any other issue
      if (!error.response) {
        error.response = {
          data: {
            message: i18n.t("errors.firewall-blocked")
          }
        };
      }
      // If response status is 401 => refresh token
      if (error.response.status === 401) {
        const _refresh_token = localStorage.getItem("refresh_token");
        // If refresh token exist => get new token
        if (_refresh_token) {
          try {
            // Get new token from refresh token
            const response = await refresh_token({
              refresh_token: _refresh_token
            });
            localStorage.setItem("token", response.data.data); // Update token in local storage
            return axios.request(error.config); // Retry request
          } catch (e) {
            // If failed to get new token => logout user
            await store.dispatch(`auth/${LOGOUT_USER}`);
            await router.push("/auth/login");
            throw "Invalid Refresh Token";
          }
        } else {
          // If refresh token not found => logout user
          await store.dispatch(`auth/${LOGOUT_USER}`);
          await router.push("/auth/login");
          throw "Invalid Refresh Token";
        }
      }
      ErrorService.onError(error.toString(), "VUE");
      return Promise.reject(error);
    }
  );
};
