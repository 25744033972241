import {
  RESET_ROOT_STATE,
  ROOT_ERROR,
  ROOT_LOADING,
  ROOT_NOTIFICATION
} from "@/store/modules/root/constants";
import { RootState } from "@/store/modules/root/interfaces";
import { AxiosError, AxiosResponse } from "axios";

export default {
  [ROOT_LOADING]: (state: RootState, payload: boolean): void => {
    state.root_loading = payload;
  },
  [ROOT_NOTIFICATION]: (
    state: RootState,
    payload: AxiosResponse | string | null
  ): void => {
    if (!payload) state.root_notification = null;
    else if (typeof payload === "string") state.root_notification = payload;
    else {
      state.root_notification = payload.data.message;
    }
  },
  [ROOT_ERROR]: (state: RootState, payload: unknown | string | null): void => {
    if (!payload) state.root_error = null;
    else if (typeof payload === "string") state.root_error = payload;
    else {
      const data = payload as AxiosError;
      state.root_error =
        data.response?.data.message ?? "Internal Error. Please try again";
    }
  },
  [RESET_ROOT_STATE]: (state: RootState): void => {
    state.root_notification = null;
    state.root_error = null;
    state.root_loading = false;
  }
};
