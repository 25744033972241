import {
  ALL_CANDIDATES,
  CANDIDATE_VIEW_PAYLOAD,
  JOBS_POSTED_BY_RECRUITER,
  JOBS_POSTED_BY_RECRUITER_LOADING,
  PUSH_TOP_JOB_WITH_CANDIDATES,
  RECOMMEND_JOB_PROFILES,
  RECRUITER_LOADING,
  REMOVE_TOP_JOBS_WITH_CANDIDATES,
  RESET_RECRUITER_STATE,
  TOP_JOBS_WITH_CANDIDATES,
  UPDATE_MATCHING_CANDIDATES,
  UPDATE_TOP_MATCHING_CANDIDATES,
  VIEW_CANDIDATES_DYNAMICALLY
} from "@/store/modules/recruiter/constants";
import {
  AllCandidates,
  CandidateViewPayload,
  JobsWithCandidates,
  RecruiterState,
  SetJobsMutationPayload,
  UpdateMatchingCandidatePayload,
  ViewCandidatesDynamically
} from "@/store/modules/recruiter/interfaces";
import { Jobs } from "@/interfaces/data_objects/jobs";

export default {
  [RECRUITER_LOADING]: (state: RecruiterState, payload: boolean): void => {
    state.loading = payload;
  },
  [JOBS_POSTED_BY_RECRUITER_LOADING]: (
    state: RecruiterState,
    payload: boolean
  ): void => {
    state.jobs_posted_loading = payload;
  },
  [JOBS_POSTED_BY_RECRUITER]: (
    state: RecruiterState,
    payload: SetJobsMutationPayload
  ): void => {
    state.jobs_posted = payload;
  },
  [TOP_JOBS_WITH_CANDIDATES]: (
    state: RecruiterState,
    payload: SetJobsMutationPayload
  ): void => {
    state.top_jobs_posted = payload;
  },
  [UPDATE_MATCHING_CANDIDATES]: (
    state: RecruiterState,
    payload: UpdateMatchingCandidatePayload
  ): void => {
    const job_index = state.jobs_posted.results.findIndex(
      (job: Jobs) => job.jid === payload.job_id
    );
    if (!payload.candidates) {
      if (job_index !== -1) {
        state.jobs_posted.results[job_index].candidates_loading = false;
      }
    } else {
      state.jobs_posted.results[job_index].candidates_loading = false;
      state.jobs_posted.results[job_index].matching_candidates =
        payload.candidates;
    }
  },
  [UPDATE_TOP_MATCHING_CANDIDATES]: (
    state: RecruiterState,
    payload: UpdateMatchingCandidatePayload
  ): void => {
    const job_index = state.top_jobs_posted.results.findIndex(
      (job: Jobs) => job.jid === payload.job_id
    );
    if (!payload.candidates) {
      if (job_index !== -1) {
        state.top_jobs_posted.results[job_index].candidates_loading = false;
      }
    } else {
      state.top_jobs_posted.results[job_index].candidates_loading = false;
      state.top_jobs_posted.results[job_index].matching_candidates =
        payload.candidates;
    }
  },
  /**
   * Removing job from top jobs with candidates data set if job_id is +ve
   */
  [REMOVE_TOP_JOBS_WITH_CANDIDATES]: (
    state: RecruiterState,
    job_id: number
  ): void => {
    state.top_jobs_posted.total -= 1;
    if (job_id >= 0) {
      state.top_jobs_posted.results = state.top_jobs_posted.results.filter(
        (v: JobsWithCandidates) => v.jid !== job_id
      );
    }
  },
  [VIEW_CANDIDATES_DYNAMICALLY]: (
    state: RecruiterState,
    payload: ViewCandidatesDynamically
  ): void => {
    state.view_candidates_dynamically = payload;
  },
  [PUSH_TOP_JOB_WITH_CANDIDATES]: (
    state: RecruiterState,
    payload: JobsWithCandidates
  ): void => {
    state.top_jobs_posted.results.pop();
    state.top_jobs_posted.results.unshift(payload);
    state.top_jobs_posted.total += 1;
  },
  // Updating recommend job profiles
  [RECOMMEND_JOB_PROFILES]: (state: RecruiterState, payload: Jobs[]): void => {
    state.recommend_job_profiles = payload;
  },
  [RESET_RECRUITER_STATE]: (state: RecruiterState): void => {
    state.loading = false;
    state.jobs_posted_loading = false;
    state.view_candidates_dynamically = {
      active: false,
      loading: false,
      job_id: null,
      job_title: ""
    };
    // Using in job listing page
    state.jobs_posted = { total: 0, results: [] };
    // Using to display jobs in dashboard
    state.top_jobs_posted = { total: 0, results: [] };
    state.recommend_job_profiles = [];
  },
  [CANDIDATE_VIEW_PAYLOAD]: (
    state: RecruiterState,
    payload: CandidateViewPayload | null
  ): void => {
    state.candidate_view_payload = payload;
  },
  [ALL_CANDIDATES]: (state: RecruiterState, payload: AllCandidates): void => {
    state.all_candidates = payload;
  }
};
