import {
  GET_JOB_BY_ID,
  ROLES,
  COMMON_LOADING,
  SET_UNIQUE_SECTORS,
  VIEW_JOB_STATE,
  RESET_COMMON_STATE
} from "@/store/modules/common/constants";
import {
  CommonState,
  SectorData,
  ViewJobState
} from "@/store/modules/common/interfaces";
import { Roles } from "@/interfaces/data_objects/roles";
import { Jobs } from "@/interfaces/data_objects/jobs";

export default {
  [COMMON_LOADING]: (state: CommonState, payload: boolean): void => {
    state.loading = payload;
  },
  [SET_UNIQUE_SECTORS]: (state: CommonState, payload: SectorData[]): void => {
    state.sectors = payload;
  },
  [ROLES]: (state: CommonState, payload: Roles[]): void => {
    state.roles = payload;
  },
  [GET_JOB_BY_ID]: (state: CommonState, payload: Jobs | null): void => {
    state.searched_job = payload;
  },
  [VIEW_JOB_STATE]: (state: CommonState, payload: ViewJobState): void => {
    state.view_job_state = payload;
  },
  [RESET_COMMON_STATE]: (state: CommonState): void => {
    state.loading = false;
    state.searched_job = null;
    state.view_job_state = ViewJobState.DEFAULT;
  }
};
