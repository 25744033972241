import {
  AUTH_LOADING,
  GET_COMPANY_DETAILS,
  GET_LATEST_WORK_HISTORY,
  GET_PASSWORD,
  GET_USER_DETAILS,
  RESET_PASS_USER_EMAIL,
  RESET_PASSWORD_TOKEN,
  USER_PROFILE,
  USER_STATE
} from "@/store/modules/auth/constants";
import { AuthState, Company, UserState } from "@/store/modules/auth/interfaces";
import { User } from "@/interfaces/data_objects/user";
import { Candidates } from "@/interfaces/data_objects/candidates";

export default {
  [AUTH_LOADING]: (state: AuthState): boolean => state.loading,
  [GET_USER_DETAILS]: (state: AuthState): User | null => state.user,
  [GET_COMPANY_DETAILS]: (state: AuthState): Company | null => state.company,
  [USER_STATE]: (state: AuthState): UserState => state.user_state,
  [USER_PROFILE]: (state: AuthState): Candidates.CandidateDetails | null =>
    state.user_profile,
  [GET_PASSWORD]: (state: AuthState): Text | undefined => state.user?.password,
  [GET_LATEST_WORK_HISTORY]: (
    state: AuthState
  ): Candidates.WorkHistoryDetails | null => {
    if (state.user_profile?.profile) {
      const work_history = state.user_profile.profile.work_history_detailed;
      if (work_history && Object.values(work_history).length > 0) {
        return Object.values(work_history)[0];
      }
    }
    return null;
  },
  [RESET_PASSWORD_TOKEN]: (state: AuthState): string | null =>
    state.reset_password_token,
  [RESET_PASS_USER_EMAIL]: (state: AuthState): string | null =>
    state.email_for_reset_password
};
