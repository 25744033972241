import getters from "@/store/modules/common/getters";
import mutations from "@/store/modules/common/mutations";
import actions from "@/store/modules/common/actions";
import { CommonState, ViewJobState } from "@/store/modules/common/interfaces";
export default {
  namespaced: true,
  state: (): CommonState => ({
    loading: false,
    sectors: [],
    roles: [],
    searched_job: null,
    view_job_state: ViewJobState.DEFAULT
  }),
  getters: getters,
  mutations: mutations,
  actions: actions
};
