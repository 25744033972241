import { BASE_URL } from "@/store/urls";
export const auth_base_url = BASE_URL + "user/auth/";
export const social_auth_base_url = BASE_URL + "user/v2/";
export const user_base_url = BASE_URL + "user/";
export const candidate_base_url = BASE_URL + "candidate/";
export const recruiter_base_url = BASE_URL + "recruiter/";

export const educational_portal_base_url = BASE_URL + "edu-portal/";

export const edu_portal_institute_base_url = educational_portal_base_url;
