import {
  GOOGLE_AUTH,
  LINKEDIN_AUTH,
  LOGIN_USER,
  LOGOUT_USER,
  AUTH_LOADING,
  SET_COMPANY,
  SET_USER,
  SET_USER_PROPERTY,
  SIGNUP_USER,
  UPDATE_USER_DETAILS,
  USER_DETAILS_BY_ID,
  USER_PROFILE,
  USER_PROFILE_BY_ID,
  USER_STATE,
  VERIFY_EMAIL,
  REHYDRATE_STATE,
  NAVIGATE_USER_BASED_ON_ROLE,
  GET_USER_DETAILS,
  FORGOT_PASSWORD,
  RESET_PASSWORD,
  UPDATE_USER_PROFILE,
  UPDATE_USER_COMPANY
} from "@/store/modules/auth/constants";
import { Commit, Dispatch } from "vuex";
import {
  ForgotPasswordPayload,
  InitUserResponse,
  LoginActionPayload,
  ResetPasswordPayload,
  SetUserPropertyPayload,
  SignupActionPayload,
  UpdateCompanyPayload,
  UserState
} from "@/store/modules/auth/interfaces";
import {
  forgot_password,
  get_user,
  get_user_profile,
  google_auth,
  linkedin_auth,
  login_user,
  reset_password,
  signup_user,
  update_user_company,
  update_user_details,
  update_user_profile,
  verify_email
} from "@/store/apis/auth";
import router from "@/router";
import { User } from "@/interfaces/data_objects/user";
import {
  GET_ROLE_BY_NAME,
  INIT_COMMON_DATA,
  RESET_COMMON_STATE,
  ROLES
} from "@/store/modules/common/constants";
import {
  RESET_ROOT_STATE,
  ROOT_ERROR,
  ROOT_NOTIFICATION
} from "@/store/modules/root/constants";
import { Candidates } from "@/interfaces/data_objects/candidates";
import { RESET_RECRUITER_STATE } from "@/store/modules/recruiter/constants";
import { RESET_CANDIDATE_STATE } from "@/store/modules/candidates/constants";
import {
  RESET_CANDIDATE_DATA,
  RESET_RECRUITER_DATA
} from "@/store/modules/onboarding/constants";
import { HCMSRoles } from "@/interfaces/data_objects/roles";
import { INIT_INSTITUTE_STATE } from "@/store/modules/institute/constants";
export default {
  /**
   * Login user function
   * @param commit
   * @param payload: LoginActionPayload
   */
  [LOGIN_USER]: async (
    { commit }: { commit: Commit },
    payload: LoginActionPayload
  ): Promise<boolean> => {
    try {
      commit(AUTH_LOADING, true);
      // Login API call
      const response = await login_user(payload);
      const user: User = response.data.data.user;
      const token = response.data.data.token;
      const refresh_token = user.refresh_token;
      localStorage.setItem("token", token);
      if (refresh_token) localStorage.setItem("refresh_token", refresh_token);
      // Set user in store
      commit(SET_USER, user);
      return true;
    } catch (e) {
      commit(AUTH_LOADING, false);
      commit(ROOT_ERROR, e, { root: true });
      return false;
    }
  },
  /**
   * Register new user
   * @param commit
   * @param payload: SignupActionPayload
   * @return boolean
   */
  [SIGNUP_USER]: async (
    { commit, dispatch }: { commit: Commit; dispatch: Dispatch },
    payload: SignupActionPayload
  ): Promise<boolean> => {
    try {
      commit(AUTH_LOADING, true);
      const response = await signup_user(payload);
      const refresh_token = response.data.user.refresh_token;
      localStorage.setItem("token", response.data.token);
      if (refresh_token) localStorage.setItem("refresh_token", refresh_token);
      // Set user in store
      commit(SET_USER, response.data.user);
      commit(USER_STATE, UserState.EMAIL_VERIFICATION_PENDING);
      await dispatch(`common/${ROLES}`, {}, { root: true });
      commit(ROOT_NOTIFICATION, "Signup Successful", { root: true });
      commit(AUTH_LOADING, false);
      return true;
    } catch (e: any) {
      commit(AUTH_LOADING, false);
      commit(ROOT_ERROR, e.response.data.message, { root: true });
      return false;
    }
  },
  /**
   * Verify email function
   * @param commit
   * @param payload
   */
  [VERIFY_EMAIL]: async (
    { commit }: { commit: Commit },
    payload: string
  ): Promise<boolean> => {
    try {
      commit(AUTH_LOADING, true);
      const response = await verify_email(payload);
      commit(USER_STATE, UserState.EMAIL_VERIFICATION_COMPLETED);
      commit(ROOT_NOTIFICATION, response.data.message, { root: true });
      const obj: SetUserPropertyPayload = {
        obj_name: "is_email_confirmed",
        value: true
      };
      commit(SET_USER_PROPERTY, obj);
      commit(AUTH_LOADING, false);
      return true;
    } catch (e) {
      commit(AUTH_LOADING, false);
      commit(ROOT_ERROR, e, { root: true });
      return false;
    }
  },
  [GOOGLE_AUTH]: async (): Promise<void> => {
    await google_auth();
  },
  [LINKEDIN_AUTH]: async (): Promise<void> => {
    await linkedin_auth();
  },
  // Fetching user profile => return upload response column from uploads table
  [USER_PROFILE]: async ({ commit }: { commit: Commit }): Promise<void> => {
    try {
      commit(AUTH_LOADING, true);
      // Fetching user profile
      const response = await get_user_profile();
      const profile_details: Candidates.CandidateDetails =
        response.data.data.upload_response;
      commit(USER_PROFILE, profile_details);
      commit(AUTH_LOADING, false);
    } catch (e) {
      commit(AUTH_LOADING, false);
      commit(USER_PROFILE);
      commit(ROOT_ERROR, e, { root: true });
    }
  },
  [USER_PROFILE_BY_ID]: async (
    { commit }: { commit: Commit },
    id: number
  ): Promise<Candidates.CandidateDetails | null> => {
    try {
      const response = await get_user_profile(id);
      return response.data.data.upload_response;
    } catch (e) {
      commit(ROOT_ERROR, e, { root: true });
      return null;
    }
  },
  [USER_DETAILS_BY_ID]: async (
    { commit }: { commit: Commit },
    id: number
  ): Promise<User | null> => {
    try {
      const response = await get_user(id);
      return response.data.data;
    } catch (e) {
      commit(ROOT_ERROR, e, { root: true });
      return null;
    }
  },
  /**
   * Update user details => name, email, password, new password
   */
  [UPDATE_USER_DETAILS]: async (
    { commit }: { commit: Commit },
    payload: SignupActionPayload
  ): Promise<boolean> => {
    try {
      commit(AUTH_LOADING, true);
      const response = await update_user_details(payload); // API call
      const user = response.data.data;
      commit(SET_USER, user);
      commit(AUTH_LOADING, false);
      return true;
    } catch (e) {
      commit(AUTH_LOADING, false);
      commit(ROOT_ERROR, e, { root: true });
      return false;
    }
  },
  [LOGOUT_USER]: async ({ commit }: { commit: Commit }): Promise<void> => {
    commit(LOGOUT_USER);
    localStorage.clear();
    commit(`candidate/${RESET_CANDIDATE_STATE}`, null, { root: true });
    commit(`recruiter/${RESET_RECRUITER_STATE}`, null, { root: true });
    commit(`common/${RESET_COMMON_STATE}`, null, { root: true });
    commit(`onboarding/${RESET_RECRUITER_DATA}`, null, { root: true });
    commit(`onboarding/${RESET_CANDIDATE_DATA}`, null, { root: true });
    commit(`${RESET_ROOT_STATE}`, null, { root: true });
    await router.push("/");
  },
  /**
   * Initialize user data
   */
  [REHYDRATE_STATE]: async ({
    commit,
    getters,
    rootGetters,
    dispatch
  }: {
    commit: Commit;
    getters: any;
    rootGetters: any;
    dispatch: Dispatch;
  }): Promise<boolean> => {
    try {
      // Get user with company details
      const response = await get_user();
      const data: InitUserResponse = response.data.data;
      const company = data.companyDetail;
      delete data.companyDetail;
      const user: User = data;
      commit(SET_USER, user);
      commit(SET_COMPANY, company);
      const refresh_token = user.refresh_token;
      if (refresh_token) localStorage.setItem("refresh_token", refresh_token);
      await dispatch(`common/${INIT_COMMON_DATA}`, {}, { root: true });
      const institute_role = rootGetters[`common/${GET_ROLE_BY_NAME}`](
        HCMSRoles["Educational Institution Representative"]
      );
      if (!user.is_account_confirmed)
        commit(USER_STATE, UserState.ONBOARDING_COMPLETED);
      else if (user.role_id === institute_role.id) {
        commit(USER_STATE, UserState.INSTITUTE);
        await dispatch(`institute/${INIT_INSTITUTE_STATE}`, {}, { root: true });
      } else {
        commit(USER_STATE, UserState.UNAUTHENTICATED);
        await dispatch(LOGOUT_USER, {});
      }
      return true;
    } catch (e) {
      await dispatch(LOGOUT_USER, {});
      return false;
    }
  },
  /**
   * Navigate user based on role or state
   * User state already set at this point and common data initialized successfully
   * @param getters
   * @param rootGetters
   */
  [NAVIGATE_USER_BASED_ON_ROLE]: async ({
    getters,
    rootGetters
  }: {
    getters: any;
    rootGetters: any;
  }): Promise<void> => {
    const user = getters[GET_USER_DETAILS];
    const institute_role = rootGetters[`common/${GET_ROLE_BY_NAME}`](
      HCMSRoles["Educational Institution Representative"]
    );
    if (!user.is_account_confirmed) await router.push("/approval-pending");
    else if (user.role_id === institute_role.id) {
      await router.push("/institute");
    } else {
      await router.push("/auth/login");
    }
  },
  [FORGOT_PASSWORD]: async (
    { commit }: { commit: Commit },
    payload: ForgotPasswordPayload
  ): Promise<boolean> => {
    try {
      commit(AUTH_LOADING, true);
      const response = await forgot_password(payload);
      commit(ROOT_NOTIFICATION, response.data.message, { root: true });
      commit(AUTH_LOADING, false);
      return true;
    } catch (e) {
      commit(ROOT_ERROR, e, { root: true });
      commit(AUTH_LOADING, false);
      return false;
    }
  },
  [RESET_PASSWORD]: async (
    { commit }: { commit: Commit },
    payload: ResetPasswordPayload
  ): Promise<boolean> => {
    try {
      commit(AUTH_LOADING, true);
      const response = await reset_password(payload);
      commit(ROOT_NOTIFICATION, response.data.message, { root: true });
      commit(AUTH_LOADING, false);
      return true;
    } catch (e) {
      commit(ROOT_ERROR, e, { root: true });
      commit(AUTH_LOADING, false);
      return false;
    }
  },
  /**
   * Function to update user profile
   */
  [UPDATE_USER_PROFILE]: async (
    { commit }: { commit: Commit },
    payload: Candidates.CandidateDetails
  ): Promise<boolean> => {
    try {
      commit(AUTH_LOADING, true);
      const response = await update_user_profile(payload);
      commit(USER_PROFILE, response.data.data.upload_response);
      commit(AUTH_LOADING, false);
      return true;
    } catch (e) {
      commit(AUTH_LOADING, false);
      return false;
    }
  },
  /**
   * FUnction to update user company
   */
  [UPDATE_USER_COMPANY]: async (
    { commit }: { commit: Commit },
    payload: UpdateCompanyPayload
  ): Promise<boolean> => {
    try {
      //
      await update_user_company(payload);
      return true;
    } catch (e) {
      return false;
    }
  }
};
