import axios, { AxiosResponse } from "axios";
import {
  CANDIDATE_ONBOARDING,
  RECRUITER_ONBOARDING
} from "@/store/urls/onboarding";

export const recruiter_onboarding = async (
  payload: FormData
): Promise<AxiosResponse> => {
  return await axios
    .post(RECRUITER_ONBOARDING, payload)
    .then((response) => response.data);
};

export const candidate_onboarding = async (
  payload: FormData
): Promise<AxiosResponse> => {
  return await axios
    .post(CANDIDATE_ONBOARDING, payload)
    .then((response) => response.data);
};
