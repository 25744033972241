import axios, { AxiosResponse } from "axios";
import {
  ACTIVATE_PUBLISHED_JOB,
  FETCH_FAVORITE_CANDIDATES,
  FETCH_MEMBERS_JOBS_STATISTICS,
  FETCH_TOTAL_JOBS_COUNT,
  GET_JOBS_POSTED_BY_RECRUITER,
  GET_MATCHING_CANDIDATES_BY_ID,
  POST_JOB,
  SOFT_DEL_JOB,
  UPDATE_JOB_DETAILS
} from "@/store/urls/recruiter";
import {
  GetJobsPostedPayload,
  MatchingCandidatesByIdPayload,
  MembersJobsStatistics,
  UpdateFavoriteCandidatePayload,
  UpdateJobPayload
} from "@/store/modules/recruiter/interfaces";

export const post_job = async (payload: FormData): Promise<AxiosResponse> => {
  return await axios.post(POST_JOB, payload);
};
// For update job
export const update_job = async (
  payload: UpdateJobPayload
): Promise<AxiosResponse> => {
  return await axios.post(UPDATE_JOB_DETAILS, payload);
};

export const get_jobs_posted_by_recruiter = async (
  payload: GetJobsPostedPayload
): Promise<AxiosResponse> => {
  return await axios.post(GET_JOBS_POSTED_BY_RECRUITER, payload);
};

export const get_matching_candidates_by_id = async (
  payload: MatchingCandidatesByIdPayload
): Promise<AxiosResponse> => {
  return await axios.post(GET_MATCHING_CANDIDATES_BY_ID, payload);
};

/**
 * Function to softly delete job
 */
export const soft_delete_job = async (job_id: {
  job_id: number;
}): Promise<AxiosResponse> => {
  return await axios.post(SOFT_DEL_JOB, job_id);
};

/**
 * Function to activate published job
 */
export const activate_published_job = async (
  job_id: number
): Promise<AxiosResponse> => {
  return await axios.post(`${ACTIVATE_PUBLISHED_JOB}${job_id}`);
};

export const fetch_total_jobs_count = async (): Promise<AxiosResponse> => {
  return await axios.get(FETCH_TOTAL_JOBS_COUNT);
};

/**
 * Function to get member jobs stats
 */
export const fetch_members_jobs_stats = async (
  payload: MembersJobsStatistics
): Promise<AxiosResponse> => {
  return await axios.post(FETCH_MEMBERS_JOBS_STATISTICS, payload);
};

/**
 * FUnction to get favorite candidate
 */
export const fetch_favorite_candidates = async (
  job_id: number
): Promise<AxiosResponse> => {
  return await axios.get(`${FETCH_FAVORITE_CANDIDATES}${job_id}`);
};

/**
 * FUnction to update favorite candidate
 */
export const update_favorite_candidate = async (
  payload: UpdateFavoriteCandidatePayload
): Promise<AxiosResponse> => {
  return await axios.post(FETCH_FAVORITE_CANDIDATES, payload);
};
