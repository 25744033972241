import { RouteConfig } from "vue-router";
import { UserState } from "@/store/modules/auth/interfaces";

export const institute_faculty: RouteConfig = {
  path: "faculty",
  name: "institute_faculty",
  component: () => import("@/views/modules/institute/faculty/Wrapper.vue"),
  meta: {
    user_state: [UserState.INSTITUTE]
  }
};
