import {
  ALL_INSTITUTES,
  COMPARISON_OTHER_SKILLS,
  COMPARISON_OTHER_SKILLS_LOADING,
  DEGREE_GAP_LOADING,
  GLOBAL_DEGREE_LOADING,
  GLOBAL_DEGREE_SKILLS,
  GLOBAL_INSTITUTES,
  INSTITUTE_STATE_LOADING,
  LOCAL_INSTITUTES,
  NATIONAL_DEGREE_LOADING,
  NATIONAL_DEGREE_SELECTED_SECTOR_JOB_MATCH_GAP,
  NATIONAL_DEGREE_SELECTED_SECTOR_JOBS,
  NATIONAL_DEGREE_SKILLS,
  NATIONAL_DEGREE_TOPJOB_SECTORS,
  NATIONAL_DEGREE_TOPJOBS_WITH_SECTORS,
  SELECTED_GLOBAL_INSTITUTE,
  SELECTED_JOB_SCORE,
  SELECTED_NATIONAL_INSTITUTE,
  UPSKILL_COURSE_PAYLOAD,
  UPSKILL_COURSES,
  UPSKILL_COURSES_LOADING
} from "@/store/modules/institute/constants";
import {
  InstituteState,
  UpskillCoursesPayload
} from "@/store/modules/institute/interfaces";
import { ExtractedSkillsWithGroup } from "@/interfaces/responses/extracted_skills_with_group";
import { TopjobsWithSectors } from "@/interfaces/responses/topjobs_with_sectors";
import { Institutes } from "@/interfaces/data_objects/institutes";
import { clean_string, format_overall_score } from "@/utils/global";
import {
  GlobalInstituteData,
  NationalInstituteData
} from "@/interfaces/modules/institute/national_global_comparison_choice";
import { InstitutesCompare } from "@/interfaces/responses/institutes_compare";
import { UpskillCourses } from "@/interfaces/data_objects/upskill_courses";

export default {
  [INSTITUTE_STATE_LOADING]: (state: InstituteState): boolean =>
    state.institute_loading,
  [DEGREE_GAP_LOADING]: (state: InstituteState): boolean =>
    state.degree_gap_loading,
  [GLOBAL_DEGREE_LOADING]: (state: InstituteState): boolean =>
    state.global_degree_loading,
  [NATIONAL_DEGREE_LOADING]: (state: InstituteState): boolean =>
    state.national_degree_loading,
  [COMPARISON_OTHER_SKILLS_LOADING]: (state: InstituteState): boolean =>
    state.comparison_other_skills_loading,
  /**
   * Getting all institutes
   * @param {InstituteState} state
   * @return {Institutes[]} => Array of institutes
   */
  [ALL_INSTITUTES]: (state: InstituteState): Institutes[] => state.institutes,
  /**
   * Get national degree skills
   * @param state
   */
  [NATIONAL_DEGREE_SKILLS]: (
    state: InstituteState
  ): ExtractedSkillsWithGroup.Root | null => state.national_degree_skills,
  /**
   * Get global degree skills
   * @param state
   */
  [GLOBAL_DEGREE_SKILLS]: (
    state: InstituteState
  ): ExtractedSkillsWithGroup.Root | null => state.global_degree_skills,
  /**
   * Return topjobs with sectors data
   * @param state
   */
  [NATIONAL_DEGREE_TOPJOBS_WITH_SECTORS]: (
    state: InstituteState
  ): TopjobsWithSectors.Root | null => state.national_degree_topjobs,
  /**
   * Return topjob sectors name
   * @param state
   */
  [NATIONAL_DEGREE_TOPJOB_SECTORS](state: InstituteState): string[] {
    if (state.national_degree_topjobs)
      return state.national_degree_topjobs.sectors.map((val) => val.name);
    return [];
  },
  /**
   * Return selected sector top jobs
   * @param state
   */
  [NATIONAL_DEGREE_SELECTED_SECTOR_JOBS]:
    (state: InstituteState) =>
    (sector_index: number): TopjobsWithSectors.TopJobs[] => {
      if (state.national_degree_topjobs?.sectors) {
        const sector = state.national_degree_topjobs.sectors[sector_index];
        return sector.top_jobs;
      }
      return [];
    },
  /**
   * Return job match & Gap of selected job
   * @param state
   */
  [NATIONAL_DEGREE_SELECTED_SECTOR_JOB_MATCH_GAP]:
    (state: InstituteState) =>
    (
      sector_index: number,
      job_index: number,
      match: boolean
    ): TopjobsWithSectors.Skills[] => {
      if (state.national_degree_topjobs) {
        const sector = state.national_degree_topjobs.sectors[sector_index];
        const job = sector.top_jobs[job_index];
        return match ? job.job_match : job.job_gap;
      }
      return [];
    },
  /**
   * Return matching score selected job
   * @param state
   */
  [SELECTED_JOB_SCORE]:
    (state: InstituteState) =>
    (sector_index: number, job_index: number): number => {
      let score = 0;
      if (state.national_degree_topjobs) {
        const sector = state.national_degree_topjobs.sectors[sector_index];
        const job = sector.top_jobs[job_index];
        score = job.matching_score;
      }
      return format_overall_score(score.toString());
    },
  /**
   * Return all local institutes
   * @param state
   */
  [LOCAL_INSTITUTES]: (state: InstituteState): Institutes[] => {
    return state.institutes.filter(
      (val) => clean_string(val.institute_type) === "local"
    );
  },
  /**
   * Return all global institutes
   * @param state
   */
  [GLOBAL_INSTITUTES]: (state: InstituteState): Institutes[] => {
    return state.institutes.filter(
      (val) => clean_string(val.institute_type) === "global"
    );
  },
  /**
   * Return selected institute data
   * @param state
   */
  [SELECTED_NATIONAL_INSTITUTE]: (
    state: InstituteState
  ): NationalInstituteData => state.selected_national_institute_data,
  /**
   * Return selected global data
   * @param state
   */
  [SELECTED_GLOBAL_INSTITUTE]: (state: InstituteState): GlobalInstituteData =>
    state.selected_global_institute_data,
  /**
   * Return comparison other skills or null if not found
   * @param state
   */
  [COMPARISON_OTHER_SKILLS]: (
    state: InstituteState
  ): InstitutesCompare.Root | null => state.comparison_other_skills,
  /**
   * Return upskill courses
   * @param state
   */
  [UPSKILL_COURSES]: (
    state: InstituteState
  ): UpskillCourses.UpskillCoursesRoot | null => state.upskill_courses,
  /**
   * Return upskill courses loading
   * @param state
   */
  [UPSKILL_COURSES_LOADING]: (state: InstituteState): boolean =>
    state.upskill_courses_loading,
  /**
   * Return upskill courses payload
   * @param state
   */
  [UPSKILL_COURSE_PAYLOAD]: (
    state: InstituteState
  ): UpskillCoursesPayload | null => state.upskill_course_payload
};
