import {
  ALL_INSTITUTES,
  COMPARISON_OTHER_SKILLS,
  COMPARISON_OTHER_SKILLS_LOADING,
  DEGREE_GAP_LOADING,
  GLOBAL_DEGREE_LOADING,
  GLOBAL_DEGREE_SKILLS,
  INSTITUTE_STATE_LOADING,
  NATIONAL_DEGREE_LOADING,
  NATIONAL_DEGREE_SKILLS,
  NATIONAL_DEGREE_TOPJOBS_WITH_SECTORS,
  RESET_INSTITUTE_STATE,
  SELECTED_GLOBAL_INSTITUTE,
  SELECTED_NATIONAL_INSTITUTE,
  UPSKILL_COURSE_PAYLOAD,
  UPSKILL_COURSES,
  UPSKILL_COURSES_LOADING
} from "@/store/modules/institute/constants";
import {
  InstituteState,
  UpskillCoursesPayload
} from "@/store/modules/institute/interfaces";
import { ExtractedSkillsWithGroup } from "@/interfaces/responses/extracted_skills_with_group";
import { TopjobsWithSectors } from "@/interfaces/responses/topjobs_with_sectors";
import { Institutes } from "@/interfaces/data_objects/institutes";
import {
  GlobalInstituteData,
  NationalInstituteData
} from "@/interfaces/modules/institute/national_global_comparison_choice";
import { InstitutesCompare } from "@/interfaces/responses/institutes_compare";
import { UpskillCourses } from "@/interfaces/data_objects/upskill_courses";

export default {
  [RESET_INSTITUTE_STATE]: (state: InstituteState): void => {
    state.institute_loading = false;
    state.degree_gap_loading = false;
    state.national_degree_skills = null;
    state.global_degree_skills = null;
    state.national_degree_topjobs = null;
    state.global_degree_loading = false;
    state.selected_national_institute_data = {
      national_institute: null,
      selected_national_degree_content: "",
      selected_national_degree_file: null,
      national_institute_program: null
    };
    state.selected_global_institute_data = {
      global_institute: null,
      selected_global_degree_content: "",
      selected_global_degree_file: null,
      global_institute_program: null
    };
    state.national_degree_loading = false;
    state.comparison_other_skills_loading = false;
    state.comparison_other_skills = null;
    state.upskill_courses = null;
    state.upskill_courses_loading = false;
    state.upskill_course_payload = null;
  },
  [INSTITUTE_STATE_LOADING]: (
    state: InstituteState,
    payload: boolean
  ): void => {
    state.institute_loading = payload;
  },
  [GLOBAL_DEGREE_LOADING]: (state: InstituteState, payload: boolean): void => {
    state.global_degree_loading = payload;
  },
  [NATIONAL_DEGREE_LOADING]: (
    state: InstituteState,
    payload: boolean
  ): void => {
    state.national_degree_loading = payload;
  },
  [COMPARISON_OTHER_SKILLS_LOADING]: (
    state: InstituteState,
    payload: boolean
  ): void => {
    state.comparison_other_skills_loading = payload;
  },
  /**
   * Setting all institutes
   * @param {InstituteState} state
   * @param {Institutes[]} payload
   */
  [ALL_INSTITUTES]: (state: InstituteState, payload: Institutes[]): void => {
    state.institutes = state.institutes.concat(payload);
  },
  [DEGREE_GAP_LOADING]: (state: InstituteState, payload: boolean): void => {
    state.degree_gap_loading = payload;
  },
  [NATIONAL_DEGREE_SKILLS]: (
    state: InstituteState,
    payload: ExtractedSkillsWithGroup.Root | null
  ): void => {
    state.national_degree_skills = payload;
  },
  [GLOBAL_DEGREE_SKILLS]: (
    state: InstituteState,
    payload: ExtractedSkillsWithGroup.Root | null
  ): void => {
    state.global_degree_skills = payload;
  },
  [NATIONAL_DEGREE_TOPJOBS_WITH_SECTORS]: (
    state: InstituteState,
    payload: TopjobsWithSectors.Root | null
  ): void => {
    state.national_degree_topjobs = payload;
  },
  /**
   * For setting selected national institute
   * @param state
   * @param {NationalInstituteData} payload
   */
  [SELECTED_NATIONAL_INSTITUTE]: (
    state: InstituteState,
    payload: NationalInstituteData
  ): void => {
    state.selected_national_institute_data = payload;
  },
  /**
   * For setting selected global institute
   * @param state
   * @param {GlobalInstituteData} payload
   */
  [SELECTED_GLOBAL_INSTITUTE]: (
    state: InstituteState,
    payload: GlobalInstituteData
  ): void => {
    state.selected_global_institute_data = payload;
  },
  /**
   * For setting COMPARISON_OTHER_SKILLS
   * @param state
   * @param {InstitutesCompare | null} payload
   */
  [COMPARISON_OTHER_SKILLS]: (
    state: InstituteState,
    payload: InstitutesCompare.Root | null
  ): void => {
    state.comparison_other_skills = payload;
  },
  /**
   * Set upskill courses
   * @param state
   * @param {UpskillCourses.UpskillCoursesRoot | null}payload
   */
  [UPSKILL_COURSES]: (
    state: InstituteState,
    payload: UpskillCourses.UpskillCoursesRoot | null
  ): void => {
    state.upskill_courses = payload;
  },
  /**
   * Set upskill courses loading
   * @param state
   * @param {boolean}payload
   */
  [UPSKILL_COURSES_LOADING]: (
    state: InstituteState,
    payload: boolean
  ): void => {
    state.upskill_courses_loading = payload;
  },
  /**
   * Set upskill course payload
   * @param state
   * @param payload
   */
  [UPSKILL_COURSE_PAYLOAD]: (
    state: InstituteState,
    payload: UpskillCoursesPayload | null
  ): void => {
    state.upskill_course_payload = payload;
  }
};
