import { recruiter_base_url } from "@/store/urls/base_urls";

export const POST_JOB = recruiter_base_url + "post-job/";

// API endpoint to get jobs posted by recruiter
export const GET_JOBS_POSTED_BY_RECRUITER = recruiter_base_url + "jobs/";
export const GET_MATCHING_CANDIDATES_BY_ID =
  recruiter_base_url + "matching-candidates/";

export const SOFT_DEL_JOB = recruiter_base_url + "delete-job";

// For change the status of job to active
export const ACTIVATE_PUBLISHED_JOB = recruiter_base_url + "publish-job/";

// Fetch recruiter dashboard numbers
export const FETCH_TOTAL_JOBS_COUNT = recruiter_base_url + "total-jobs/";

// For updating job
export const UPDATE_JOB_DETAILS = recruiter_base_url + "update-job/";

// For getting members/jobs statistics
export const FETCH_MEMBERS_JOBS_STATISTICS = recruiter_base_url + "stats/";

// For get favorite candidate against job & update favorite candidate against job
export const FETCH_FAVORITE_CANDIDATES = recruiter_base_url + "favorite/";
