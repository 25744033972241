import {
  GET_JOB_MATCH_AGAINST_JOB_ID,
  GET_MATCHING_JOBS,
  INIT_CANDIDATE,
  SEARCH_ANY_JOB,
  SET_JOBS_LOADING,
  SET_MATCHING_JOBS,
  UPSKILL_COURSES,
  UPSKILL_COURSES_LOADING
} from "@/store/modules/candidates/constants";
import { Commit, Dispatch } from "vuex";
import {
  get_matching_jobs,
  search_whole_system_jobs,
  skill_gap_against_job,
  upskill_courses
} from "@/store/apis/candidate";
import {
  MatchingJob,
  SearchAnyJobPayload,
  UpskillCoursesActionPayload
} from "@/store/modules/candidates/interfaces";
import { ROOT_ERROR } from "@/store/modules/root/constants";
import router from "@/router";
import { USER_PROFILE } from "@/store/modules/auth/constants";
import { GetJobMatchActionPayload } from "@/store/modules/common/interfaces";
import { Candidates } from "@/interfaces/data_objects/candidates";
import { Jobs } from "@/interfaces/data_objects/jobs";

export default {
  [GET_MATCHING_JOBS]: async (
    { commit }: { commit: Commit },
    payload = 6
  ): Promise<void> => {
    try {
      commit(SET_JOBS_LOADING, true);
      const response = await get_matching_jobs(payload);
      const top_jobs: MatchingJob[] = response.data.top_jobs;
      commit(SET_MATCHING_JOBS, top_jobs);
      commit(SET_JOBS_LOADING, false);
    } catch (e) {
      commit(SET_JOBS_LOADING, false);
      commit(ROOT_ERROR, e, { root: true });
    }
  },
  /**
   * Get upskill courses
   */
  [UPSKILL_COURSES]: async (
    { commit }: { commit: Commit },
    payload: UpskillCoursesActionPayload
  ): Promise<void> => {
    try {
      const response = await upskill_courses(payload);
      commit(UPSKILL_COURSES, response.data.data);
      // commit(UPSKILL_COURSES, json_d);
    } catch (e) {
      commit(ROOT_ERROR, e, { root: true });
      commit(UPSKILL_COURSES_LOADING, false);
      await router.push({
        name: "candidate-error"
      });
    }
  },
  /**
   * Initialize candidate data
   */
  [INIT_CANDIDATE]: async ({
    dispatch
  }: {
    dispatch: Dispatch;
  }): Promise<boolean> => {
    try {
      await dispatch(`auth/${USER_PROFILE}`, {}, { root: true });
      return true;
    } catch (e) {
      return false;
    }
  },
  /**
   * Action to get job match by job id
   * @param job_id:number
   */
  [GET_JOB_MATCH_AGAINST_JOB_ID]: async (
    { commit }: { commit: Commit },
    payload: GetJobMatchActionPayload
  ): Promise<Candidates.Candidates | boolean> => {
    try {
      // API call
      const response = await skill_gap_against_job(payload);
      const data = response.data.data;
      // If data received and have matched job details
      if (data.length > 0 && data[0].cvs.length > 0) {
        return data[0].cvs[0];
      } else return false;
    } catch (e) {
      return false;
    }
  },
  [SEARCH_ANY_JOB]: async (
    { commit }: { commit: Commit },
    payload: SearchAnyJobPayload
  ): Promise<Jobs[]> => {
    try {
      commit(SET_JOBS_LOADING, true);
      const response = await search_whole_system_jobs(payload);
      commit(SET_JOBS_LOADING, false);
      return response.data.data.results;
    } catch (e) {
      commit(SET_JOBS_LOADING, false);
      return [];
    }
  }
};
