export const AUTH_LOADING = "AUTH_LOADING";

export const LOGIN_USER = "LOGIN_USER";
export const SIGNUP_USER = "SIGNUP_USER";

export const VERIFY_EMAIL = "VERIFY_EMAIL";

export const SET_USER = "SET_USER";
export const SET_USER_PROPERTY = "SET_USER_PROPERTY";
export const GET_USER_DETAILS = "GET_USER_DETAILS";
export const INITIALIZE_USER = "INITIALIZE_USER";

export const SET_CODE = "SET_CODE";
export const GET_CODE = "GET_CODE";

export const GOOGLE_AUTH = "GOOGLE_AUTH";
export const LINKEDIN_AUTH = "LINKEDIN_AUTH";

export const GET_COMPANY_DETAILS = "GET_COMPANY_DETAILS";
export const SET_COMPANY = "SET_COMPANY";

export const USER_STATE = "USER_STATE";

export const USER_PROFILE = "USER_PROFILE";

export const USER_PROFILE_BY_ID = "USER_PROFILE_BY_ID";
export const USER_DETAILS_BY_ID = "USER_DETAILS_BY_ID";

export const UPDATE_USER_DETAILS = "UPDATE_USER_DETAILS";
export const GET_PASSWORD = "GET_PASSWORD";

export const GET_LATEST_WORK_HISTORY = "GET_LATEST_WORK_HISTORY";
export const LOGOUT_USER = "LOGOUT_USER";

// For rehydrate state & initializing data
export const REHYDRATE_STATE = "REHYDRATE_STATE";

// For navigating user based on role
export const NAVIGATE_USER_BASED_ON_ROLE = "NAVIGATE_USER_BASED_ON_ROLE";

// For reset password
export const RESET_PASSWORD = "RESET_PASSWORD";
// For fetching email token for resetting password
export const FORGOT_PASSWORD = "FORGOT_PASSWORD";

// For setting/getting reset-password token in store
export const RESET_PASSWORD_TOKEN = "RESET_PASSWORD_TOKEN";

// For setting/getting user email during reset-password
export const RESET_PASS_USER_EMAIL = "RESET_PASS_USER_EMAIL";
// For updating profile
export const UPDATE_USER_PROFILE = "UPDATE_USER_PROFILE";

// For updating company
export const UPDATE_USER_COMPANY = "UPDATE_USER_COMPANY";
