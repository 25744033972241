import {
  COMMON_LOADING,
  FETCH_SKILLS_GROUP,
  GET_JOB_BY_ID,
  GET_UNIQUE_SECTORS,
  INIT_COMMON_DATA,
  JOB_SEARCH,
  ROLES,
  SET_UNIQUE_SECTORS,
  SKILL_SEARCH,
  UPLOAD_FILE
} from "@/store/modules/common/constants";
import { Commit, Dispatch } from "vuex";
import {
  fetch_skills,
  fetch_skills_group,
  get_job_by_id,
  get_roles_list,
  get_unique_sectors
} from "@/store/apis/common";
import { ROOT_ERROR } from "@/store/modules/root/constants";
import { Jobs } from "@/interfaces/data_objects/jobs";
import { GetJobsPostedPayload } from "@/store/modules/recruiter/interfaces";
import { get_jobs_posted_by_recruiter } from "@/store/apis/recruiter";
import router from "@/router";
import { SkillSearchPayload } from "@/store/modules/common/interfaces";
import { Skills } from "@/interfaces/data_objects/skills";
import { upload_program } from "@/store/apis/instutute";
import { AxiosResponse } from "axios";

export default {
  /**
   * Get unique sectors/industries
   */
  [GET_UNIQUE_SECTORS]: async ({
    commit
  }: {
    commit: Commit;
  }): Promise<void> => {
    try {
      commit(COMMON_LOADING, true);
      const response = await get_unique_sectors();
      commit(SET_UNIQUE_SECTORS, response.data);
      commit(COMMON_LOADING, false);
    } catch (e) {
      //
    }
  },
  [ROLES]: async ({ commit }: { commit: Commit }): Promise<void> => {
    try {
      const response = await get_roles_list();
      commit(ROLES, response.data.data);
    } catch (e) {
      await router.push("/error");
    }
  },
  /**
   * Function to get job by jid(primary key)
   */
  [GET_JOB_BY_ID]: async (
    { commit }: { commit: Commit },
    job_id: number
  ): Promise<Jobs | null> => {
    try {
      commit(COMMON_LOADING, true);
      // await new Promise((resolve) => setTimeout(resolve, 4000));
      const response = await get_job_by_id(job_id);
      commit(GET_JOB_BY_ID, response.data.data);
      commit(COMMON_LOADING, false);
      return response.data.data;
    } catch (e) {
      commit(COMMON_LOADING, false);
      commit(GET_JOB_BY_ID, null);
      commit(ROOT_ERROR, e, { root: true });
      return null;
    }
  },
  /**
   * Fetch skills group => payload: skills: string[]
   */
  [FETCH_SKILLS_GROUP]: async (
    { commit }: { commit: Commit },
    payload: string[]
  ): Promise<any> => {
    try {
      commit(COMMON_LOADING, true);
      const obj = {
        skills: payload
      };
      const form_data = new FormData();
      form_data.append("skills", payload as never);
      const response = await fetch_skills_group(obj);
      commit(COMMON_LOADING, false);
      return response.data.data.skill_group;
    } catch (e) {
      commit(COMMON_LOADING, false);
      return null;
    }
  },
  [JOB_SEARCH]: async (
    { commit }: { commit: Commit },
    payload: GetJobsPostedPayload
  ): Promise<Jobs[]> => {
    try {
      commit(COMMON_LOADING, true);
      // await new Promise((resolve) => setTimeout(resolve, 3000));
      const response = await get_jobs_posted_by_recruiter(payload);
      commit(COMMON_LOADING, false);
      return response.data.data.results;
    } catch (e) {
      commit(COMMON_LOADING, false);
      commit(ROOT_ERROR, null, { root: true });
      return [];
    }
  },
  /**
   * Action to initialize startup data
   */
  [INIT_COMMON_DATA]: async ({
    dispatch
  }: {
    dispatch: Dispatch;
  }): Promise<boolean> => {
    try {
      await dispatch(ROLES);
      await dispatch(GET_UNIQUE_SECTORS);
      return true;
    } catch (e) {
      await router.push("/error");
      return false;
    }
  },
  /**
   * Action to search skills
   */
  [SKILL_SEARCH]: async (
    { commit }: { commit: Commit },
    payload: SkillSearchPayload
  ): Promise<Skills[]> => {
    try {
      commit(COMMON_LOADING, true);
      const response = (await fetch_skills(payload)).data.data.results;
      commit(COMMON_LOADING, false);
      return response;
    } catch (e) {
      return [];
    }
  },
  [UPLOAD_FILE]: async (
    { commit }: { commit: Commit },
    payload: FormData
  ): Promise<AxiosResponse | null> => {
    try {
      return await upload_program(payload);
    } catch (e) {
      commit(ROOT_ERROR, e, { root: true });
      return null;
    }
  }
};
